<template>
    <li v-show="count != 0" class="shopping-cart" @click="clickRequestList">
        <a>
            <div class="mobile copy">My Request list</div>
            <div class="icon">
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                <div class="count"><p v-html="count"></p></div>
            </div>
        </a>
    </li>
</template>

<script>
  
import { EventBus } from '../events/event-bus.js';

export default {
    data() {
        return {
            count:0,
            selectedProducts:null
        };
    },
    mounted() {
        this.updateCart();
        EventBus.$on('selected-products-change', () => this.updateCart());
    },
    methods: {
        updateCart(){
            var previousSelected = sessionStorage.getItem("mifi-selected-products");
            // console.log("updateCart() previousSelected: ", previousSelected);
            if(previousSelected){
                this.selectedProducts = previousSelected.split(',');
                this.count = this.selectedProducts.length;
                // console.log("count: " + this.count + " : ",this.selectedProducts);
            } else {
                this.count = 0;
            }
        },
        clickRequestList(){
            window.location = '/request';
        }
        
    }
};
</script>