<template>
    <div class="product-search">
        <form class="form-inline my-lg-0" method="get" action="/">
            <input name="s" role="search" class="form-control" type="search" placeholder="Product Search">
            <button class="btn" type="submit"><i class="fa fa-search" aria-hidden="true"></i></button>
        </form>
    </div>
</template>

<script>
  
// import { EventBus } from '../events/event-bus.js';

export default {
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    }
};
</script>