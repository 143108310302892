<template>
    <div>
        <div class="filters">
            <div  v-for="(term, key, index) in terms" :key="index" :id="term.slug" class="btn btn-solid-red" :class="termClass(term.slug)" @click="selectTerms(term.slug)">
            {{term.name}}
            </div>
        </div>
        <div class="news-container">
            <a v-for="(item, key, index) in news" :key="index" :href="item.link" :target="item.target" v-show="filterType(item.slug)">
                <article :class="item.class">
                    <div class="image" v-bind:style="{ 'background-image': 'url(' + item.image + ')' }">
                    </div>
                    <div v-if="item.slug != 'video'" class="timestamp" v-html="item.date">
                    </div>
                   
                    <header>
                        <h2 class="entry-title" v-html="item.title"></h2>
                    </header>

                    <div class="read-more">
                        <div class="copy" v-html="item.cta"></div>
                        <!-- <div class="chevron"></div> -->
                        <div class="chevron-container">
                            <svg class="animated-chevron" image-rendering="auto" baseProfile="basic" version="1.1" preserveAspectRatio="xMidYMid meet" viewBox="0 0 62 35"><rect x="0" y="14.7" width="55.2" height="4.55"></rect><g transform="translate(40.9 0)"><path stroke="none" d="M.35 3L14.3 17.2 0 31.25 3.35 34.25 20.85 17.15 3.5 0 .35 3Z"></path></g></svg>
                        </div>
                    </div>
                </article>
            </a>
        </div>
    </div>
</template>

<script>
  

export default {
    data() {
        return {
            terms:null,
            news:[],

            selectedTerms:['press']
        };
    },
    created(){

    },
    mounted() {
       
        axios.post("/wp-json/theplan/v1/getnews")
            .then((response) => {
                this.news = response.data;
            })  
            .catch( (error) => {
                console.log("!!!! error:");
        });
        axios.post("/wp-json/theplan/v1/getnewsterms")
            .then((response) => {
                this.terms = response.data;
                for(var i=0;i<response.data.length;i++){
                    //This adds each term dynamically as it came in (for future post types)
                    // this.selectedTerms.push(response.data[i]['slug']);
                }
            })  
            .catch( (error) => {
                console.log("!!!! error:", response.data);
        });
    },
    methods: {
        selectTerms(term){

            //Each filter use to toggle seperately - quick fix is reset array each time
            // const index = this.selectedTerms.findIndex(t => t == term)
            // if (index >= 0){
            //     this.selectedTerms.splice(index, 1)
            // } else {
            //     this.selectedTerms.push(term)
            // }

            this.selectedTerms = [term];
        },
        termClass(term){
            const isSelected = this.selectedTerms.includes(term)
            return {
                'active': isSelected,
                'inactive': !isSelected
            }
        },
        filterType(slug){
            const isFiltered = this.selectedTerms.includes(slug)
            return isFiltered;
        }
        
    },
};
</script>