<template>
<div class="card ">
    <div class="card-header" v-bind:id="'heading'+data.icon+index">
      <h5 class="mb-0">
        <button
            class="btn btn-link " :class="{'collapsed':data.showCollapse, 'collapsed':!currentlyActive}" 

            :aria-expanded="data.showCollapse ? 'true' : 'false'"

            v-bind:aria-controls="'collapse'+data.icon+index"
            data-toggle="collapse"
            v-bind:data-target="'#collapse'+data.icon+index"

            @click="checkSingleSubcategory()"
            >
            <!-- <div ref="chevron" class="chevron up" :class="{hide:data.subcategories.length === 1, down:data.showCollapse, up:!data.showCollapse}" ></div> -->
            <div ref="chevron" class="chevron up" :class="{hide:data.subcategories.length === 1}" ></div>
            <div class="icon-stack">
                <div class="icon " v-bind:class="data.icon"></div>
                <div class="fill"></div>
            </div>
            <div class="label">{{data.label}}</div>
        </button>
      </h5>
    </div>
   
    <div
        v-bind:id="'collapse'+data.icon+index"

        class="collapse "
        v-bind:class="data.showCollapse ? 'show' : null"

        v-bind:aria-labelledby="'heading'+data.icon+index"
        data-parent="#accordion"
    >
      <div class="card-body" v-if="data.subcategories.length != 1">
          <div class="subcategory-btn" @click="subcategoryClick(subcategory)" v-for="(subcategory, key, index) in data.subcategories" :key="index" v-html="subcategory.name.replace('--', ', ')" :class="{active:currentSelected === subcategory.term_id}"></div>
      </div>
    </div>
</div>
</template>

<script>
import { EventBus } from '../events/event-bus.js';
// import VueTrace from '../bluevertex/util/VueTrace'
export default {
    props: {
        data: {type:Object},
        index:String,
        type:String
    },
     data() {
        return {
            currentSelected:null,
            currentlyActive:false,
        }
    },
    mounted(){
        // Vue.config.errorHandler = function (err, vm, info)  {
        //    //console.log('[Global Error Handler]: Error in ' + info + ': ' + err);
        // };
        EventBus.$on('on-show-subcategory', (id) => this.onShowSubcategory(id));
        EventBus.$on('activate-category', (id) => this.onActivateCategory(id));
        // EventBus.$on('show-subcategory', () => this.clearSelected());

        ////console.log('category data: ', this.data);
        ////console.log('category data sub.length: ' + this.data.subcategories.length);
        if(this.data.subcategories.length == 1){
            ////console.log("ONLY ONE SUBCATEGORY ");
            this.$refs.chevron
        }
        // this.$emit(this.data.);
    },
    methods:{
        clearSelected(){
            this.currentSelected = null;
            this.currentlyActive = false;
        },
        onShowSubcategory(subcategoryTermId){
            this.currentSelected = subcategoryTermId;
           //console.log(">>onShowSubcategory: " + subcategoryTermId + " = " + this.currentSelected.term_id);
            ////console.log("data.subcategories: ", data.subcategories.term_id);
        },
        onActivateCategory(categoryTermId){
            if(this.type != 'search-result'){
               //console.log(       ">>onActivateCategory: " + categoryTermId + " = " + this.index)
                if(this.index == categoryTermId){
                    this.currentlyActive = true;
                } else {
                    this.currentlyActive = false;
                }
                // this.currentSelected = subcategoryTermId;
            };
        },
        subcategoryClick(subcategory){
        //    console.log(">>subcategoryClick: " + subcategory + " type: " + this.type);

            if(this.type == 'search-result'){
                // console.log("  >>EventBus.$emit('on-goto-result" + this.index + "') : "+subcategory.term_id + " : " + this.data.term_id);
                EventBus.$emit('on-goto-result'+this.index, subcategory.term_id, this.data.term_id);
            } else {
                // console.log("  >>EventBus.$emit('show-subcategory')" + this.index + " : "+subcategory.term_id + " : " + this.data.term_id);
                //Opens subcategory
                EventBus.$emit('show-subcategory', subcategory.term_id, this.data.term_id);
                
                //Adds 'active' class to selected subcategory
                this.currentSelected = subcategory.term_id;
            }
        },
        checkSingleSubcategory(){
            ////console.log("this.type: "+ this.type);
           //console.log(">>checkSingleSubcategory: " + this.data.subcategories.length);
            // VueTrace.log('{blue}category first term_id(){/blue} ' + this.data.subcategories[0].term_id);
            // VueTrace.log('{blue}category term_id(){/blue} ' + this.data.term_id);
            
            if(this.data.subcategories.length === 1){
                //Check if not just accordion but a search result.t button
                if(this.type == 'search-result'){
                    //Single category
                    EventBus.$emit('on-goto-result'+this.index, this.data.subcategories[0].term_id, this.data.term_id);
                } else {
                    //Open 
                    EventBus.$emit('show-subcategory', this.data.subcategories[0].term_id, this.data.term_id);
                }
            }
        }
    }
}

</script>