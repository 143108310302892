<template>
    <div class="selected-products">

        <div class="product-btn">
            <span
                @click="gotoViewProducts()"
                class="fa-stack fa-lg"
            >
                <i
                    class="fa fa-circle-o fa-stack-2x"
                    aria-hidden="true"
                ></i>
                <i
                    class="fa fa-plus fa-stack-1x"
                    aria-hidden="true"
                ></i>
            </span>
            <div class="label">Add onto my list</div>
        </div>
        <hr>
        <div
            class=""
            v-show="this.displayedProducts.length > 0"
        >
            <!--
            0: ["Tartaric Acid", 219] <- ID to remove all matching products with same product ID
            1:  0:  ["Food", "Bakery", "219-10-9"]
                1:  ["Food", "Beverages", "219-10-13"] <- options additional subcategories
        -->
            <div
                class="product-container"
                v-for="(product, index) in displayedProducts"
                :key="product[1][0][2]"
            >
                <div class="product-btn">
                    <span
                        @click="removeProduct(product[0][1], index)"
                        class="fa-stack fa-lg"
                    >
                        <i
                            class="fa fa-circle-o fa-stack-2x"
                            aria-hidden="true"
                        ></i>
                        <i
                            class="fa fa-minus fa-stack-1x"
                            aria-hidden="true"
                        ></i>
                    </span>
                    <div
                        class="label"
                        v-html="product[0][0]"
                    ></div>
                </div>
                <div class="subcategories">
                    <div
                        class="subcategory-btn"
                        v-for="(subcategory, subIndex) in product[1]"
                        @click="removeSubcategory(index, subIndex, product[0][1], subcategory[2] , $event )"
                    >
                        <span
                            class="fa-stack fa-lg"
                            style="pointer-events: none;"
                        >
                            <!-- <i class="fa fa-circle-o fa-stack-2x" aria-hidden="true"></i> -->
                            <i
                                class="fa fa-minus fa-stack-1x"
                                aria-hidden="true"
                            ></i>
                        </span>
                        <div
                            class="label"
                            v-html="subcategory[1]"
                            style="pointer-events: none;"
                        ></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { EventBus } from '../events/event-bus.js';
// import VueTrace from "../bluevertex/util/VueTrace";

export default {
    data() {
        return {
            storedProducts: [],
            products: [],
            displayedProducts: []
        };
    },
    mounted() {
        // VueTrace.output(sessionStorage.getItem("mifi-selected-products"));
        if (sessionStorage.getItem('mifi-selected-products')) {
            this.storedProducts = sessionStorage
                .getItem('mifi-selected-products')
                .split(',');
            // var grouped = this.storedProducts.split(",");

            this.storedProducts.forEach(product => {
                var split = product.split('-');
                var id = split[0];
                var category = split[1];
                var subcategory = split[2];

                var exists = this.products.find(el => el[0] === id);
                var combo = [category, subcategory];
                if (exists) {
                    exists[1].push(combo);
                } else {
                    var item = [id, [combo]];
                    this.products.push(item);
                }
            });

            //Get actual product data
            axios
                .post('/wp-json/theplan/v1/convertuniques', {
                    productArray: this.products
                })
                .then(response => {
                    // response.data.forEach(product => {
                    //     console.log("found product: ", product);
                    //     this.displayedProducts.push(product);
                    // });
                    this.displayedProducts = response.data;
                });

            // this.updateCart();
        }
    },
    methods: {
        updateCart() {
            //   console.log("updateCart() selectedProducts: ", this.displayedProducts);
        },
        removeProduct(id, index) {
            // console.log('id: ' + id);
            // console.log('index: ' + index);

            var newArray = [];

            this.storedProducts.forEach(function(product) {
                var _id = product.slice(0, product.indexOf('-'));
                if (String(id) != _id) {
                    newArray.push(product);
                } else {
                    // console.log("  MATCH: " + id +" : " +_id);
                }
            });
            //Removed all instances with that product id ie(219);
            this.storedProducts = newArray;

            this.displayedProducts.splice(index, 1);

            sessionStorage.setItem(
                'mifi-selected-products',
                this.storedProducts
            );
            EventBus.$emit('selected-products-change');
        },

        //index, subIndex, product[0][1], subcategory[2] , $event
        removeSubcategory(index, subIndex, id, uniqueId, event) {
            // console.log('index: ' + index);
            // console.log('subIndex: ' + subIndex);
            // console.log('id: ' + id);
            // console.log('uniqueId: ' + uniqueId);
            /*
        0: ["Tartaric Acid", 219] <- ID to remove all matching products with same product ID
        1:  0:  ["Food", "Bakery", "219-10-9"]
            1:  ["Food", "Beverages", "219-10-13"] <- options additional subcategories
        */

            //Need to check if only subcategory left - else causes key error
            // console.log("remainingSubcategories: " + this.displayedProducts[index][1].length);
            if (this.displayedProducts[index][1].length == 1) {
                // console.log("!!!! only one!!!");
                //this.displayedProducts = this.displayedProducts.filter(function (product) {console.log("  remove parent category: " + id + " : " +product[0].ID); return product[0].ID != id; });
                this.displayedProducts.splice(index, 1);
            } else {
                //Remove correct subcategory child from display list
                this.displayedProducts[index][1].splice(subIndex, 1);
            }
            this.storedProducts = this.storedProducts.filter(function(
                storedId
            ) {
                EventBus.$emit('selected-products-change');
                return storedId != uniqueId;
            });

            sessionStorage.setItem(
                'mifi-selected-products',
                this.storedProducts
            );
            EventBus.$emit('selected-products-change');
        },
        gotoViewProducts() {
            window.location = '/view-products/?category=10';
        }
    },
    watch: {
        displayedProducts: function() {
            let htmlList = '<p style="text-align: left;">';
            for (let product of this.displayedProducts) {
                let productname = product[0].toString();
                let categories = product[1];

                for(let second of categories){
                    let secondCategoyName = [...second].slice(0, -1).join(', ');
                    htmlList += `<div style="margin-bottom: 14px;"><div style="color: #d91c24;">${
                        productname.split(',')[0]
                    }</div><div>• ${secondCategoyName}</div></div>`;
                };
            }
            htmlList += '</p>';

            const requestedItems = htmlList;
            // var requestedItems = JSON.stringify(this.displayedProducts);
            // console.log("JSON.stringify: ", requestedItems);
            // console.log("JSON.parse: ", JSON.parse(requestedItems));
            var hiddenField = document.getElementById('wpforms-731-field_13');

            if (hiddenField) {
                hiddenField.value = requestedItems;
            }
        }
    }
};
</script>
