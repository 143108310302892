<template>
<div>
    <div class="message" v-html="welcome"></div>
    <div class="products row">
        <div class="col-lg-4 left">
            <product-search></product-search>
            <div class="filters">
                <h3>Filter Search</h3>
                <hr>
                <div id="accordion" class="categories">
                    <category-button v-for="(item, key, index) in categories" :index="String(item.term_id)" :key="index" :data="item"></category-button>
                </div>
            </div>
        </div>
        <div class="col-lg-8 right" v-show="showProducts">
            <a class="anchor" id="category-display"></a>
            <div class="subcategories">
                <h1 v-if="subcategoryLabel && itemsContains(subcategoryLabel)" v-html="subcategoryLabel.replace('--', ', ')"></h1>
                <h1 v-else v-html="subcategoryLabel"></h1>
                <div class="btn-container">
                <a @click="clickRequestList()" class="btn btn-solid-red small">View My Request List</a>
                </div>
                <div class="subcategory-description" v-html="subcategoryDesc"></div>
                <hr>
                <div class="product-item" v-for="(product, key, index) in products" :key="index">
                    <div class="product-btn">
                        <div class="product-label" v-html="product.post_title" @click="clickProduct(product.ID, String(product.ID)+'-'+currentCategory+'-'+currentSubcategory)"></div>
                        <!-- <div class="request-btn"><input v-model="selectedProducts" type="checkbox" :id="product.ID+'-'product.category+'-'+product.sub" :value="product.ID"><label :for="product.ID">Request Info</label></div> -->
                        <!-- <div class="request-btn"><input v-model="selectedProducts" type="checkbox" :id="product.ID" :value="product.ID"><label :for="product.ID">Request Info</label></div> -->
                        <div @click="addRequestList(String(product.ID)+'-'+currentCategory+'-'+currentSubcategory)" class="btn btn-solid-red small" v-if="!selectedProducts.includes(String(product.ID)+'-'+currentCategory+'-'+currentSubcategory)">
                        + Request Info
                        </div>
                        <div @click="removeRequestList(String(product.ID)+'-'+currentCategory+'-'+currentSubcategory)" class="btn btn-solid-red small grey" v-if="selectedProducts.includes(String(product.ID)+'-'+currentCategory+'-'+currentSubcategory)" >
                        - Remove from List
                        </div>
                    </div>
                    <hr>
                </div>
            </div>
        </div> 
        <div class="col-lg-8 right" v-if="showProduct">
            <a class="anchor" id="product-display"></a>
            <div class="product">
                <div class="label-container">
                    <i @click="goBack()" class="fa fa-arrow-left" aria-hidden="true"></i>
                    <h1 v-html="currentProductLable"></h1>
                </div>
                <div class="buttons">
                    <div @click="addRequestList(selectedProductUniqueId)" class="btn btn-solid-red small" v-if="!selectedProducts.includes(selectedProductUniqueId)">
                        + Request Info
                    </div>
                    <div @click="removeRequestList(selectedProductUniqueId)" class="btn btn-solid-red small grey" v-if="selectedProducts.includes(selectedProductUniqueId)" >
                        - Remove from List
                    </div>
                    <a @click="clickRequestList" class="btn btn-solid-red small">View My Request List</a>
                </div>
                <div class="product-description" v-html="currentProductDesc"></div>
                
                <hr>
                <div class="logo" v-if="currentProductLogo" v-bind:style="{ 'background-image': 'url(' + currentProductLogo + ')' }"></div>
                
                <div v-show="currentProductOther && currentProductOther.length > 0">
                    <p><strong>Additional Product Category Usage</strong></p>
                    <div class="additional-categories">
                        <div class="icon-container" v-for="(other, key, index) in currentProductOther" :key="index">
                            <div @click="gotoCategory(other.term_id)" class="btn btn-solid-red small" v-html="other.name">
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>

                <div v-show="currentProductOtherSub && currentProductOtherSub.length > 0">
                    
                    <p><strong>Additional Product Subcategory Usage</strong></p>
                    <div class="additional-categories">
                        <div class="icon-container" v-for="(other, key, index) in currentProductOtherSub" :key="index">
                            <div @click="gotoSubCategory(other.unique_id)" class="btn btn-solid-red small" v-html="other.name.replace('--', ', ')">
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                <div v-show="currentProductKeywords && currentProductKeywords.length > 0">
                <p><strong>Related Label Specifications Available Upon Request</strong></p>
                <ul class="related">
                    <li v-for="(keyword, key, index) in currentProductKeywords" :key="index" v-html="keyword.name"></li>
                </ul>
                </div>



                <div class="disclaimer" v-show="hasBetterForYou">
                    <p>*Better for you applies to one or more of the following, but not limited to: Natural, lower sodium, MSG free, gluten free, low fat, low calorie, lower sugar, sugar free, non-dairy and more. Please contact us for more details.</p>
                </div>

            </div>
        </div> 
        

    </div>
</div>
</template>

<script>
  
import { EventBus } from '../events/event-bus.js';
import ProductSearch from './product-search.vue';
import CategoryButton from './category-button.vue';
// import VueTrace from '../bluevertex/util/VueTrace'

export default {
    props: {
        welcome: {type:String},
    },
    components: {
        ProductSearch, CategoryButton
    },
    data() {
        return {
            productQuery:undefined,
            categoryQuery:undefined,
            subcategoryQuery:undefined,

            categories:null,
            subcategories:null,

            subcategoryLabel:null,
            subcategoryDesc:null,
            products:null,

            currentCategory:null,
            currentSubcategory:null,
            currentProduct:null,

            currentProductLable:null,
            currentProductDesc:null,
            currentProductLogo:null,
            currentProductOther:null,
            currentProductOtherSub:null,
            currentProductKeywords:null,
            hasBetterForYou:false,

            selectedProductUniqueId:null,

            selectedProducts:[],

            showProducts:false,
            showProduct:false,
        };
    },
    created(){

    },
    mounted() {
        var previousSelected = sessionStorage.getItem("mifi-selected-products");
        if(previousSelected){
            this.selectedProducts = previousSelected.split(',');
        }

        EventBus.$on('show-subcategory', (subId, catId) => this.onShowSubcategory(subId, catId));

        ////console.log("## $options.name: "+ this.$options._componentTag);
        axios.post("/wp-json/theplan/v1/categories")
            .then((response) => {
               //console.log("#### response: ", response.data)
                this.categories = response.data;
                // this.checkQuery();
                this.$nextTick(() => {
                    this.checkQuery();
                });
            })  
            .catch( (error) => {
                ////console.log("!!!! error:", response.data);
            });
    },
    methods: {
        itemsContains(name) {
            return name.indexOf('--') > -1
        },
        getUrlParameter(name) {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            var results = regex.exec(location.search);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        },
        checkQuery(){
            //New edit - have to have productid, category, and subcategory to work phase 3
            this.productQuery = parseInt(this.getUrlParameter('productid'));
            this.categoryQuery = parseInt(this.getUrlParameter('category'));
            this.subcategoryQuery = parseInt(this.getUrlParameter('subcategory'));

            //Reguardless is productQuery - open category and subcategory (from Home page red wedge nav)
            if( !isNaN(this.categoryQuery) ){
                // var category = this.categories.filter(function (entry) { return entry.term_id === this.categoryQuery; });
                var category = this.categories.filter(entry => entry.term_id === this.categoryQuery);
               //console.log('  >>found category: ', category);
                category[0]['showCollapse'] = true; //open category

           
                if(isNaN(this.subcategoryQuery)){
                    //No subcategory in query - use first one in the category
                    this.subcategoryQuery = category[0].subcategories[0].term_id;
                } 
                this.selectedProductUniqueId = this.productQuery+'-'+this.categoryQuery+'-'+this.subcategoryQuery;
                EventBus.$emit('on-show-subcategory', this.subcategoryQuery);
                this.onShowSubcategory(this.subcategoryQuery, this.categoryQuery);
            }

            
        },
        goBack(){
            this.showProduct = false;
            this.showProducts = true;

            this.productQuery = null;
            this.categoryQuery = null;
            this.subcategoryQuery = null;
        },
        categoryButtonLoaded(id){
            // VueTrace.output(id);
        },
        getProduct(productId){
            ////console.log("  >>getProduct: " + productId);
            // VueTrace.output(productId);
            axios.post("/wp-json/theplan/v1/product", {
                    id:productId,
                    currentSub:this.currentSubcategory
                })
                .then((response) => {
                    ////console.log(" getProduct: ", response.data);
                    ////console.log(" currentCategory: ", this.currentCategory);
                    this.currentProduct = response.data[0];
                    this.currentProductLable = this.currentProduct.post_title;
                    this.currentProductDesc = this.currentProduct.post_content;
                    this.currentProductLogo = (response.data[1] != null)?response.data[1]['url'] : null ;
                    this.currentProductOther = response.data[2].filter((el) => { return el.term_id != this.currentCategory; });
                    this.currentProductKeywords = response.data[3];

                    this.currentProductOtherSub = response.data[4].filter((el) => { return el.term_id != this.currentSubcategory; });

                    this.hasBetterForYou = this.currentProductKeywords.some(function(el){ return el.slug === 'better-for-you'});
                    
                    this.showProduct = true;
                    this.showProducts = false;
                    ////console.log("pre nexttick this.showProduct " + this.showProduct);
                    ////console.log("pre nexttick this.showProducts " + this.showProducts);
                    // // location.hash = "#product-display";
                    this.$nextTick(() => {
                        
                        ////console.log(" >>nexttick this.showProduct " + this.showProduct);
                        ////console.log(" >>nexttick this.showProducts " + this.showProducts);
                        if(document.getElementById('product-display')){
                            if(window.innerWidth <= 992 || window.innerHeight <= 768) {
                                document.getElementById('product-display').scrollIntoView(true);
                            }
                        }
                    });
                })  
        },
        onShowSubcategory(subId, catId){
            // console.log("      >>onShowSubcategory: " + subId + " : " + catId);
           
            axios.post("/wp-json/theplan/v1/subcategory", {
                    subcategory_id:subId,
                    category_id:catId
                })
                .then((response) => {
                    ////console.log("  <<subcategory ", response.data);
                    this.currentCategory = catId;
                    this.currentSubcategory = subId;
                    this.subcategoryLabel = response.data.label;
                    this.subcategoryDesc = response.data.desc;

                    this.showProduct = false;
                    this.showProducts = true;
                    this.products = response.data.products;

                    this.categoryQuery = undefined;
                    this.subcategoryQuery = undefined;

                    if( !isNaN(this.productQuery) ){
                        // VueTrace.output('productQuery: ' +  this.productQuery);
                        this.getProduct(this.productQuery);
                        this.productQuery = undefined;
                    }
                   
                    EventBus.$emit('activate-category', catId);
                    this.$nextTick(() => {
                        //If on mobile should scroll to change in subcategory
                        var el = document.getElementById('category-display');
                        if(el){
                            ////console.log("SCROLL TO!!! window.innherHeight: " + window.innerHeight);
                            if(window.innerWidth <= 992 || window.innerHeight <= 768 ) {
                                //element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                                // -or- 
                                // var target = document.getElementById("target");
                                // target.parentNode.scrollTop = target.offsetTop;
                                document.getElementById('category-display').scrollIntoView(true);
                            }
                        }
                    });
                })
        },
        //TODO https://stackoverflow.com/questions/824349/how-do-i-modify-the-url-without-reloading-the-page
        clickProduct(productId, uniqueId){
            // VueTrace.log("{red}clickProduct{/red}");
           //console.log("  >>clickProduct: " + productId + " uniqueId: " + uniqueId);
           //console.log("  >>currentCategory :"+ this.currentCategory);
           //console.log("  >>currentSubcategory :"+this.currentSubcategory);

            var urlPath = '/view-products/?category='+ this.currentCategory+'&subcategory='+this.currentSubcategory+'&productid='+productId;
            // window.history.pushState("object or string", "Title", "/new-url");
            window.history.pushState({"html":"to do previous page content?","pageTitle":this.currentProductLable},this.currentProductLable, urlPath);
            this.selectedProductUniqueId = uniqueId;
            this.getProduct(productId);
        },
        clickRequestList(){
            window.location = '/request';
        },
        gotoCategory(catId){
            window.location = 'view-products/?category='+ catId;
        },
        gotoSubCategory(uniqueId, subId){
            var split = uniqueId.split('-');
            var id = split[0];
            var category = split[1];
            var subcategory = split[2];
            //console.log("########### uniqueId: " + uniqueId + " : " + id + " : "+ category+ " : "+subcategory +" = currentSubdId "+subId);
            var query = '/view-products/?category='+category+'&subcategory='+subcategory+'&productid='+id;
            window.location = query;
        },
        addRequestList(uniqueId){
            // VueTrace.log(">>addRequestList("+uniqueId+")");

            // VueTrace.log('{#80079E}currentCategory(){/#80079E} ' + this.currentCategory);
            // VueTrace.log('{#80079E}currentSubcategory(){/#80079E} ' + this.currentSubcategory);

            if(!this.selectedProducts.includes(uniqueId)){
                this.selectedProducts.push(uniqueId);
                // VueTrace.output(">>addRequestList("+uniqueId+") {blue}added{/blue}");
            } else {
                // VueTrace.output(">>addRequestList("+uniqueId+") {red}ALREADY IN ARRAY{/red}");
            }
            sessionStorage.setItem("mifi-selected-products", this.selectedProducts);
            EventBus.$emit('selected-products-change');
        },
        removeRequestList(uniqueId){
            if(this.selectedProducts.includes(uniqueId)){
                var index =  this.selectedProducts.indexOf(uniqueId);
                if (index > -1) {
                    this.selectedProducts.splice(index, 1);
                }
                // VueTrace.output(">>removeRequestList("+uniqueId+") {green}removd{/green}");
            } else {
                // VueTrace.output(">>removeRequestList("+uniqueId+") {red}NOT FOUND IN ARRAY{/red}");
            }
            sessionStorage.setItem("mifi-selected-products", this.selectedProducts);
            EventBus.$emit('selected-products-change');
        },
        sortedArray(x){
            return _.orderBy(x, 'post_title', 'asc');
        }

    },
    watch:{
        // selectedProducts: function(){
        //    //console.log('selectedProducts watch: ', this.selectedProducts);
        //     sessionStorage.setItem("mifi-selected-products", this.selectedProducts);
        //     EventBus.$emit('selected-products-change');
        // }
    },
    // computed: {
    //     sortedArray(){
    //         function compare(a, b) {
    //             if (a.post_title < b.post_title){
    //                 return -1;
    //             }
    //             if (a.post_title > b.post_title){
    //                 return 1;
    //             }
    //             return 0;
    //         }
    //         return this.products.sort(compare);
    //     }
        // sortedArray: function() {
        //     function compare(a, b) {
        //         if (a.post_title < b.post_title){
        //             return -1;
        //         }
        //         if (a.post_title > b.post_title){
        //             return 1;
        //         }
        //         return 0;
        //     }

        //     return this.products.sort(compare);
        // }
    // }
};
</script>