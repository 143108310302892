window.Vue = require('vue');
var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
window.axios = require('axios');

// import { EventBus } from './events/event-bus.js';
import RequestedProducts from './components/requested-products.vue';
import ProductSearch from './components/product-search.vue';
import Products from './components/products.vue';
import SearchResult from './components/search-result.vue';
import ShoppingCart from './components/shopping-cart.vue';
import RequestForm from './components/request-form.vue';
import NewsFilters from './components/news-filters.vue';

const app = new Vue({
    el: '#app',
    components: {
        ProductSearch, Products, ShoppingCart, RequestForm, NewsFilters, SearchResult, RequestedProducts
    }
});

var hero = null;
var yScrollPosition = 0;

// Works on desktop browsers only
if (navigator.userAgent.match(/Trident\//) || navigator.userAgent.match(/Edge\//)) {
    document.body.addEventListener("mousewheel", function(evt) {
        evt.preventDefault();
        var wd = evt.wheelDelta;
        var csp = window.pageYOffset;
        window.scrollTo(0, csp - wd);
    });
}

window.addEventListener("DOMContentLoaded", function(event) {
    
    //Flex boxes with class '.equal-height' will find the max height and set all to the same on resize.
    setEqualHeight();

    //Check if page has hero image, then set height to regular page content and turn on parallax effect
    hero = document.getElementsByClassName('header-image')[0];
    if(hero){
        headerImageHeight();
        window.addEventListener('scroll', function() {
            onScrollHandler();
        });
        // parallaxRequestAnimationFrame();
    }
});

function setTranslate(yPos, el) {
    // el.style.transform = "translate3d(" + xPos + ", " + yPos + "px, 0)";
    el.style.transform = "translateY(" + yPos + "px)";
}

function onScrollHandler(){
    yScrollPosition = window.pageYOffset;
    setTranslate(yScrollPosition * -0.2, hero);
}

jQuery(window).resize(function(event){
    setEqualHeight();
    if(hero){
        headerImageHeight();
    }
});

function headerImageHeight(){
        var _height;
        // console.log("jQuery('.page-home') = "+jQuery(".page-home")[0]);
        if (jQuery(".page-home")[0]){
            // console.log("  Is Home page: ");
            //If home page use the position of icons div in red wedge
            _height = jQuery('.panel-grid:eq( 2 )').offset().top;
        } else {
            // All other pages with header image

            //check for jumbotron
            var _height;
            var _jumbotron = jQuery('.tout-jumbotron')[0];
            // console.log("_jumbotron: ", _jumbotron);
            if(_jumbotron){
                var _secondPanel = jQuery('.panel-grid:eq( 1 )')[0];
                // console.log("_secondPanel: ", _secondPanel);
                if(_secondPanel){
                    _height = jQuery('.panel-grid:eq( 1 )').offset().top;
                    // console.log("_secondPanel _height: ", _height);
                } else {
                    var _fullWidth = jQuery('.full-width').first();
                    // console.log("no second use _full-width ", _fullWidth);
                    _height = _fullWidth.offset().top;
                    // var _top = _fullWidth.offset().top;
                    // console.log("_top ", _top);
                    // var _margin = parseFloat(_jumbotron.css("marginBottom"));
                    // console.log("_margin ", _margin);
                    // _height = _firstPanel.offset().top + _jumbotron.height() + _margin;
                }
            } else {
                //No Jumbotron - find top of first panel-grid
                var _firstPanel = jQuery('.panel-grid:eq( 0 )');
                if(!!_firstPanel.offset()) _height = _firstPanel.offset().top;
            }
        }
        // console.log("Hero height: " + _height);
        jQuery('.header-image').height(_height).css({ 'opacity' : 1 });
}

//Look for class .equal-height and find the largest height then set all to that height
function setEqualHeight(){
    //remove inline height even on mobile
    jQuery('.equal-height').each(function(){
        jQuery(this).css("height", "")
    });
    if(screen.width > 992){
        let _max = 0;
        jQuery('.equal-height').each(function(){
            let _height = jQuery(this).height();
            if(_height > _max) _max = _height;
        });
        jQuery('.equal-height').each(function(){
            jQuery(this).height(_max);
        });
    }
}

//Not using constant firing request animation frame for Parallax effect
// function parallaxRequestAnimationFrame() {
//     if(hero){
//         yScrollPosition = window.scrollY;
//         setTranslate(0, yScrollPosition * -0.2, hero);
//         requestAnimationFrame(parallaxRequestAnimationFrame);
//     }
// }
