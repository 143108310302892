<template>
    <ValidationObserver v-slot="{ invalid }">
        <div class="request-form">
            <form
                action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                method="POST"
            >
                <input
                    type="hidden"
                    name="captcha_settings"
                    :value="captchaSettings"
                />
                <input type="hidden" name="oid" :value="oid" />
                <input
                    type="hidden"
                    name="retURL"
                    value="https://mifiusa.com/thank-you/"
                />
                <input
                    type="hidden"
                    id="rating"
                    name="rating"
                    :value="rating"
                />
                <input
                    type="hidden"
                    name="lead_source"
                    id="lead_source"
                    value="MIFI Website - Direct Traffic"
                />
                <textarea
                    v-if="type == 'request_form'"
                    class="hidden-form"
                    type="text"
                    :id="requestedItemsId"
                    :name="requestedItemsId"
                    wrap="soft"
                ></textarea>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col align-self-center">
                            <ul class="salesforce-form">
                                <li class="input-container">
                                    <label class="form-label" for="first_name"
                                        >First Name*</label
                                    >
                                    <div>
                                        <ValidationProvider
                                            name="First Name"
                                            rules="required|alpha_spaces"
                                            v-slot="{ errors }"
                                        >
                                            <input
                                                v-model="firstName"
                                                class="field text fn full"
                                                id="first_name"
                                                maxlength="40"
                                                name="first_name"
                                                size="20"
                                                type="text"
                                                required
                                            />
                                            <span class="errors">{{
                                                errors[0]
                                            }}</span>
                                        </ValidationProvider>
                                    </div>
                                </li>
                                <li class="input-container">
                                    <label class="form-label" for="last_name"
                                        >Last Name*</label
                                    >
                                    <div>
                                        <ValidationProvider
                                            name="Last Name"
                                            rules="required|alpha_spaces"
                                            v-slot="{ errors }"
                                        >
                                            <input
                                                v-model="lastName"
                                                class="field text ln full"
                                                id="last_name"
                                                maxlength="80"
                                                name="last_name"
                                                size="20"
                                                type="text"
                                                required
                                            />
                                            <span class="errors">{{
                                                errors[0]
                                            }}</span>
                                        </ValidationProvider>
                                    </div>
                                </li>

                                <li class="input-container">
                                    <label class="form-label" for="company"
                                        >Company Name</label
                                    >
                                    <div>
                                        <input
                                            class="field text full"
                                            id="company"
                                            maxlength="80"
                                            name="company"
                                            size="20"
                                            type="text"
                                        />
                                    </div>
                                </li>

                                <li class="input-container">
                                    <label class="form-label" for="url"
                                        >Company Website Address*</label
                                    >
                                    <div>
                                        <ValidationProvider
                                            name="Url"
                                            :rules="{
                                                regex: /^((http|https):\/\/)?(www\.)?([A-z]+)\.([A-z]{2,})/,
                                            }"
                                            v-slot="{ errors }"
                                        >
                                            <input
                                                v-model="url"
                                                class="field text full"
                                                id="url"
                                                maxlength="80"
                                                name="url"
                                                size="20"
                                                type="url"
                                                required
                                            />
                                            <span class="errors">{{
                                                errors[0]
                                            }}</span>
                                        </ValidationProvider>
                                    </div>
                                </li>

                                <li class="input-container">
                                    <label class="form-label" for="email"
                                        >Email*</label
                                    >
                                    <div>
                                        <ValidationProvider
                                            name="E-mail"
                                            rules="required|email"
                                            v-slot="{ errors }"
                                        >
                                            <input
                                                v-model="email"
                                                class="field text full"
                                                id="email"
                                                maxlength="80"
                                                name="email"
                                                size="20"
                                                type="email"
                                                required
                                            />
                                            <span class="errors">{{
                                                errors[0]
                                            }}</span>
                                        </ValidationProvider>
                                    </div>
                                </li>

                                <li class="input-container">
                                    <label class="form-label" for="phone"
                                        >Phone</label
                                    >
                                    <div>
                                        <ValidationProvider
                                            name="Phone"
                                            rules="numeric"
                                            v-slot="{ errors }"
                                        >
                                            <the-mask
                                                v-model="phone"
                                                :mask="['(###) ###-####']"
                                                class="field text full"
                                                id="phone"
                                                maxlength="40"
                                                name="phone"
                                                size="20"
                                                type="text"
                                            />
                                            <span class="errors">{{
                                                errors[0]
                                            }}</span>
                                        </ValidationProvider>
                                    </div>
                                </li>

                                <li class="input-container">
                                    <label class="form-label" for="country_code"
                                        >Country*</label
                                    >
                                    <div>
                                        <ValidationProvider
                                            name="Country"
                                            rules="required"
                                            v-slot="{ errors }"
                                        >
                                            <select
                                                v-model="country"
                                                class="field text ln full"
                                                id="country_code"
                                                name="country_code"
                                                required
                                            >
                                                <option value="">
                                                    --None--
                                                </option>
                                                <option value="CA">
                                                    Canada
                                                </option>
                                                <option value="US">
                                                    United States
                                                </option>
                                                <option value="OTHER">
                                                    Other
                                                </option>
                                            </select>
                                            <span class="errors">{{
                                                errors[0]
                                            }}</span>
                                        </ValidationProvider>
                                    </div>
                                </li>

                                <li v-if="showStates" class="input-container">
                                    <label class="form-label" for="state_code"
                                        >State*</label
                                    >
                                    <div>
                                        <ValidationProvider
                                            rules="required_if:country,US"
                                            v-slot="{ errors }"
                                        >
                                            <select
                                                v-model="state"
                                                class="field text ln full"
                                                id="state_code"
                                                name="state_code"
                                                required
                                            >
                                                <option value="">
                                                    --None--
                                                </option>
                                                <option value="AL">
                                                    Alabama
                                                </option>
                                                <option value="AK">
                                                    Alaska
                                                </option>
                                                <option value="AZ">
                                                    Arizona
                                                </option>
                                                <option value="AR">
                                                    Arkansas
                                                </option>
                                                <option value="CA">
                                                    California
                                                </option>
                                                <option value="CO">
                                                    Colorado
                                                </option>
                                                <option value="CT">
                                                    Connecticut
                                                </option>
                                                <option value="DE">
                                                    Delaware
                                                </option>
                                                <option value="DC">
                                                    District Of Columbia
                                                </option>
                                                <option value="FL">
                                                    Florida
                                                </option>
                                                <option value="GA">
                                                    Georgia
                                                </option>
                                                <option value="HI">
                                                    Hawaii
                                                </option>
                                                <option value="ID">
                                                    Idaho
                                                </option>
                                                <option value="IL">
                                                    Illinois
                                                </option>
                                                <option value="IN">
                                                    Indiana
                                                </option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">
                                                    Kansas
                                                </option>
                                                <option value="KY">
                                                    Kentucky
                                                </option>
                                                <option value="LA">
                                                    Louisiana
                                                </option>
                                                <option value="ME">
                                                    Maine
                                                </option>
                                                <option value="MD">
                                                    Maryland
                                                </option>
                                                <option value="MA">
                                                    Massachusetts
                                                </option>
                                                <option value="MI">
                                                    Michigan
                                                </option>
                                                <option value="MN">
                                                    Minnesota
                                                </option>
                                                <option value="MS">
                                                    Mississippi
                                                </option>
                                                <option value="MO">
                                                    Missouri
                                                </option>
                                                <option value="MT">
                                                    Montana
                                                </option>
                                                <option value="NE">
                                                    Nebraska
                                                </option>
                                                <option value="NV">
                                                    Nevada
                                                </option>
                                                <option value="NH">
                                                    New Hampshire
                                                </option>
                                                <option value="NJ">
                                                    New Jersey
                                                </option>
                                                <option value="NM">
                                                    New Mexico
                                                </option>
                                                <option value="NY">
                                                    New York
                                                </option>
                                                <option value="NC">
                                                    North Carolina
                                                </option>
                                                <option value="ND">
                                                    North Dakota
                                                </option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">
                                                    Oklahoma
                                                </option>
                                                <option value="OR">
                                                    Oregon
                                                </option>
                                                <option value="PA">
                                                    Pennsylvania
                                                </option>
                                                <option value="RI">
                                                    Rhode Island
                                                </option>
                                                <option value="SC">
                                                    South Carolina
                                                </option>
                                                <option value="SD">
                                                    South Dakota
                                                </option>
                                                <option value="TN">
                                                    Tennessee
                                                </option>
                                                <option value="TX">
                                                    Texas
                                                </option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">
                                                    Vermont
                                                </option>
                                                <option value="VA">
                                                    Virginia
                                                </option>
                                                <option value="WA">
                                                    Washington
                                                </option>
                                                <option value="WV">
                                                    West Virginia
                                                </option>
                                                <option value="WI">
                                                    Wisconsin
                                                </option>
                                                <option value="WY">
                                                    Wyoming
                                                </option>
                                            </select>
                                            <span class="errors">{{
                                                errors[0]
                                            }}</span>
                                        </ValidationProvider>
                                    </div>
                                </li>

                                <li
                                    v-if="type == 'contact_form'"
                                    class="input-container"
                                >
                                    <label class="form-label" for="country_code"
                                        >Product Category Help*</label
                                    >
                                    <div>
                                        <ValidationProvider
                                            name="Product Category Help"
                                            rules="required"
                                            v-slot="{ errors }"
                                        >
                                            <select
                                                v-model="productCategory"
                                                class="field text ln full"
                                                :id="productCategoryId"
                                                :name="productCategoryId"
                                                title="Product Category Help"
                                            >
                                                <option value="">
                                                    --None--
                                                </option>
                                                <option
                                                    value="Active Pharmaceutical Ingredients"
                                                >
                                                    Active Pharmaceutical
                                                    Ingredients
                                                </option>
                                                <option value="Animal Feed">
                                                    Animal Feed
                                                </option>
                                                <option value="Bakery">
                                                    Bakery
                                                </option>
                                                <option value="Beverages">
                                                    Beverages
                                                </option>
                                                <option value="Biotech">
                                                    Biotech
                                                </option>
                                                <option
                                                    value="Candy &amp; Confectionery"
                                                >
                                                    Candy &amp; Confectionery
                                                </option>
                                                <option value="Cosmetics">
                                                    Cosmetics
                                                </option>
                                                <option value="Dairy">
                                                    Dairy
                                                </option>
                                                <option
                                                    value="Dressings, Sauces &amp; Condiments"
                                                >
                                                    Dressings, Sauces &amp;
                                                    Condiments
                                                </option>
                                                <option value="Excipients">
                                                    Excipients
                                                </option>
                                                <option
                                                    value="Flavors, Spices &amp; Seasonings"
                                                >
                                                    Flavors, Spices &amp;
                                                    Seasonings
                                                </option>
                                                <option value="Meat">
                                                    Meat
                                                </option>
                                                <option
                                                    value="Nutrition &amp; Supplements"
                                                >
                                                    Nutrition &amp; Supplements
                                                </option>
                                                <option value="Other">
                                                    Other
                                                </option>
                                                <option
                                                    value="Pet Food &amp; Treats"
                                                >
                                                    Pet Food &amp; Treats
                                                </option>
                                                <option
                                                    value="Processed &amp; Prepared Foods"
                                                >
                                                    Processed &amp; Prepared
                                                    Foods
                                                </option>
                                                <option value="Solvents">
                                                    Solvents
                                                </option>
                                            </select>
                                            <span class="errors">{{
                                                errors[0]
                                            }}</span>
                                        </ValidationProvider>
                                    </div>
                                </li>

                                <li
                                    v-if="type == 'contact_form'"
                                    class="input-container"
                                >
                                    <label class="form-label" for="description"
                                        >Description</label
                                    >
                                    <div>
                                        <textarea
                                            name="description"
                                            class="textarea text full"
                                            rows="5"
                                        ></textarea>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col align-self-center">
                            <div
                                class="g-recaptcha"
                                :data-sitekey="recaptchaKey"
                            ></div>
                        </div>
                    </div>
                </div>
                <div class="container" style="margin-top: 2em">
                    <div class="row align-items-center">
                        <div class="col align-self-center">
                            <input
                                :disabled="invalid"
                                class="btn btn-solid-red"
                                type="submit"
                                name="submit"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </ValidationObserver>
</template>

<script>
import { EventBus } from '../events/event-bus.js';
import { TheMask } from 'vue-the-mask';
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate/dist/vee-validate.full.esm';

export default {
    props: {
        type: { type: String },
        oid: { type: String },
        rating: { type: String },
        productCategoryId: { type: String },
        requestedItemsId: { type: String },
        recaptchaKey: { type: String }
    },
    components: { TheMask, ValidationProvider, ValidationObserver },
    data() {
        return {
            storedProducts: [],
            products: [],
            displayedProducts: [],
            country: null,
            state: '',
            // Models for validation
            firstName: '',
            lastName: '',
            email: '',
            url: 'https://',
            phone: null,
            productCategory: null
        };
    },
    mounted() {
        if (this.type == 'request_form') {
            this.updateProducts();
            EventBus.$on('selected-products-change', () =>
                this.updateProducts()
            );
        }

        setInterval(this.timestamp, 500);
    },
    computed: {
        showStates() {
            return this.country == 'US';
        },
        captchaSettings() {
            return JSON.stringify({
                keyname: 'MIFIUSA',
                fallback: 'true',
                orgId: this.oid,
                ts: ''
            });
        }
    },
    methods: {
        timestamp() {
            var response = document.getElementById('g-recaptcha-response');
            if (response == null || response.value.trim() == '') {
                var elems = JSON.parse(
                    document.getElementsByName('captcha_settings')[0].value
                );
                elems['ts'] = JSON.stringify(new Date().getTime());
                document.getElementsByName(
                    'captcha_settings'
                )[0].value = JSON.stringify(elems);
            }
        },
        updateProducts() {
            if (sessionStorage.getItem('mifi-selected-products')) {
                this.storedProducts = sessionStorage
                    .getItem('mifi-selected-products')
                    .split(',');

                this.products = [];
                this.storedProducts.forEach(product => {
                    var split = product.split('-');
                    var id = split[0];
                    var category = split[1];
                    var subcategory = split[2];

                    var exists = this.products.find(el => el[0] === id);
                    var combo = [category, subcategory];
                    if (exists) {
                        exists[1].push(combo);
                    } else {
                        var item = [id, [combo]];
                        this.products.push(item);
                    }
                });

                //Get actual product data
                axios
                    .post('/wp-json/theplan/v1/convertuniques', {
                        productArray: this.products
                    })
                    .then(response => {
                        this.displayedProducts = response.data;

                        // console.log(" this.displayedProducts",this.displayedProducts);
                        let htmlList = '';
                        for (let product of this.displayedProducts) {
                            let productname = product[0].toString();
                            let categories = product[1];

                            for (let second of categories) {
                                let secondCategoyName = [...second]
                                    .slice(0, -1)
                                    .join(', ');
                                htmlList += `${
                                    productname.split(',')[0]
                                } • ${secondCategoyName}\n`;
                            }
                        }
                        // htmlList += '</p>';

                        // var requestedItems = JSON.stringify(this.displayedProducts);
                        // console.log("JSON.stringify: ", requestedItems);
                        // console.log("JSON.parse: ", JSON.parse(requestedItems));
                        const requestedItems = htmlList;

                        var hiddenField = document.getElementById(
                            this.requestedItemsId
                        );
                        if (!!hiddenField) {
                            hiddenField.value = requestedItems;
                        }
                    });
            } else {
                //Clear field if nothing in session storage
                var hiddenField = document.getElementById(
                    this.requestedItemsId
                );
                if (hiddenField) {
                    hiddenField.value = '';
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
span.errors {
    display: block;
    color: #d91c24;
}
</style>
