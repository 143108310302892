<template>
    <div>
    <div class="result">
		<h2 v-html="title">
        </h2>
        <hr>
	</div>
    <div class="subcategory-tags">
        <div v-for="(item, key, index) in data" :key="item.unique_id">
            <div @click="onGotoResult(item.unique_id)" class="btn btn-solid-red small" v-html="item.name.replace('--', ', ')"></div>
        </div>
    </div>
    <!-- <div id="accordion" class="categories">
        <category-button v-for="(item, key, index) in data" :index="id" :key="index" :data="item" type="search-result"></category-button>
    </div> -->
    </div>
</template>

<script>
  
import { EventBus } from '../events/event-bus.js';
import CategoryButton from './category-button.vue';
// import VueTrace from '../bluevertex/util/VueTrace'

export default {
    props: {
        id:String,
        title:String,
    },
    components: {
        CategoryButton
    },
    data() {
        return {
            data:null,
        };
    },
    created(){

    },
    mounted() {
        // EventBus.$on('on-goto-result'+this.id, (subId, catId) => this.onGotoResult(subId, catId));
        axios.post("/wp-json/theplan/v1/productcategories", {
                    id:this.id
                })
                .then((response) => {
                    // console.log(":: response ", response.data);
                    this.data = response.data;
        })  
    },
    methods: {
        onGotoResult(unique_id){
            var split = unique_id.split('-');
            var id = split[0];
            var category = split[1];
            var subcategory = split[2];
            // console.log("  searchResult.vue >>onGotoResult");
            // console.log("########### uniqueId: " + unique_id + " : " + id + " : "+ category+ " : "+subcategory);
            window.location = 'view-products/?category='+ category+'&subcategory='+subcategory+'&productid='+id;
        },
    },
};
</script>